<template>
  <section id="dashboard-ecommerce">
   
     <b-card class="blog-edit-wrapper">
     <b-card-header
     style="background-color:#2c3d57;color:#fffff"
>
  <b-card-title > Welcome To Control Panel
  </b-card-title>
  </b-card-header>
  <b-card-body>
  <b-row>
  <b-col cols="12">
 <span class="label"> User Name: </span> {{Userdata.name}}
</b-col>
<!-- <b-col  cols="12"> -->
  <!-- <span class="label"> Role:     </span>    {{Userdata.roles[0]}} -->
<!-- </b-col> -->
<b-col  cols="12">
  <span class="label"> Latest login Time:  </span>    {{Userdata.last_login_at}}
</b-col>
<b-col  cols="12">
  <span class="label"> Latest login IP:  </span>   {{Userdata.last_login_ip}}
</b-col>
</b-row>
</b-card-body>
  </b-card>
    
   

   
  </section>
</template>

<script>
import { BRow, BCol,BCard,BCardTitle,BCardHeader,BCardBody } from 'bootstrap-vue'



export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BCardHeader,
BCard,BCardTitle
   
  },
  data() {
    return {
      Userdata: {},
    }
  },
  created() {
   

  this.$store.dispatch('setting/getInfo')
        .then(response => {
          this.Userdata=response.data.data
          console.log("profil",response.data.data)
          
        })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
h4.card-title {
    color: #fff;
}
.col-12 {
    margin: 15px;
}
.label {
    font-weight: 900;
    margin: 5px;
}
</style>
